@import url(https://fonts.googleapis.com/css?family=Lato:400,400italic,700,300);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700,300,200);
@mobile: ~"only screen and (max-height: 840px)";

@color-grey: #c2c1c1;

body, html {
  height: 100%;
  @media @mobile {
    position: relative;
    height: auto;
    display: block;
    min-height: 100%;
  }
body {
  background: #aacfc1;
  color: #FFF;
  background: url("../Img/bg-01.jpg") center center no-repeat;
  background-size: cover;
  font-family: 'Lato', sans-serif;
  height: 100%;
  @media @mobile {
    position: static;
  }
}
}
a {
  color: #fff;
  &:hover,
  &:active {
    color: darken(#fff, 5%);
  }
}

body > header {
  padding: 30px;
  .logo {
    max-width: 249px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .logout {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 30px;
  }
}

body > main#main-login {
  position: absolute;
  width: 320px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -160px;
  text-align: center;

  @media @mobile {
    position: relative;
    height: auto;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 200;
    font-family: 'Open Sans', sans-serif;
  }
}
body > main#main {
  position: relative;
  padding-top: 0px;
  padding-bottom: 60px;
  margin: auto;
  max-width: 600px;
}

.overview-container {
  margin: 0 -30px;
  padding: 10px;
  > .ow-item {
    padding: 30px;
    width: 33.3333%;
    float: left;
    text-align: center;
    > .ow-icon {
      display: block;
      padding: 0 0 100% 0;
      border: 1px solid #fff;
      border-radius: 15px;
      background: fade(#fff, 20%) no-repeat center;
      background-size: 60%;
      &.webcam {
        background-image: url("../Img/webcamplus-icon.png");
      }
    }
    .ow-title {
      font-weight: 300;
    }
  }
}

.login-box {
  padding: 0 10px;
  @media @mobile {
    margin: 200px 0 120px 0;
  }

  .form-group {
    &.attach {
      margin: 0;
      > input {
        border-radius: 3px;
        border: 1px solid @color-grey;
        background: fade(#fff, 80%);
        margin: 0;
        padding: 10px 20px;
        -webkit-font-smoothing: antialiased;
        width: 100%;
        outline: none;
        box-sizing: border-box;
        vertical-align: middle;
        color: #000;
        &::-webkit-input-placeholder { /* Safari, Chrome and Opera */
          color: #999999;
        }
        &:-moz-placeholder {
          color: #999999;
        }
        &::-moz-placeholder {
          color: #999999;
        }
        &:-ms-input-placeholder {
          color: #999999;
        }
        &::-ms-input-placeholder {
          color: #999999;
        }
        &:placeholder-shown {
          color: #999999;
        }
      }
    }
    &.attach.bottom > input {
      border-top: 0;
      border-radius: 0 0 6px 6px;
      margin-bottom: 15px;
    }
    &.attach.top > input {
      border-radius: 6px 6px 0 0;
    }
  }
  .btn-login {
    background: fade(#000, 30%);
    padding: 5px 15px;
    &:hover {
      color: #fff;
      background: fade(#000, 80%);
    }
  }
  .checkbox {
    text-align: left;
    padding-left: 15px;
    margin-bottom: 0;
    margin-top: 5px;
    label {
      font-weight: 300;
    }
  }


}

body > footer {
  background: url("../Img/bg-01.jpg") center no-repeat;
  background-size: cover;
  border-top: 1px solid #FFF;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  @media @mobile {
    height: auto;
  }
  .footer-info {
    text-align: center;
    padding: 15px;
    font-size: 12px;
    font-weight: 300;
  }
}
